// components/Games.js
import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import GameCarousel from "./utils/GameCarousel"; // Import the GameCarousel component
import GameGrid from "./utils/GameGrid"; // Import the GameGrid component
import { motion } from "framer-motion";

const Games = ({ setLiveCCU, settotalVisits }) => {
  const [games, setGames] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [retryCount, setRetryCount] = useState(0);
  const [activeFilter, setActiveFilter] = useState("All");
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredGames, setFilteredGames] = useState([]);
  const originalGamesRef = useRef([]);

  // Format large numbers to simplified format (e.g., 240,293,430 to 240M)
  const formatLargeNumber = (num) => {
    if (!num) return "0";
    
    // Remove commas if present
    const cleanNum = typeof num === 'string' ? num.replace(/,/g, '') : num;
    const numValue = Number(cleanNum);
    
    if (isNaN(numValue)) return "0";
    
    if (numValue >= 1_000_000_000) {
      return Math.floor(numValue / 1_000_000_000) + 'B';
    } else if (numValue >= 1_000_000) {
      return Math.floor(numValue / 1_000_000) + 'M';
    } else if (numValue >= 1_000) {
      return Math.floor(numValue / 1_000) + 'K';
    } else {
      return numValue.toString();
    }
  };

  useEffect(() => {
    let intervalId;

    const fetchGames = async () => {
      try {
        const response = await axios.get("https://utopia.sparkle.gg/public/games-list");
        
        // Clear any previous errors when request succeeds
        if (error) {
          setError(null);
        }
        
        console.log("Fetched games:", response.data.games.length);
        const fetchedGames = response.data.games;
        setGames(fetchedGames);
        originalGamesRef.current = fetchedGames; // Store original games for reset
        setLoading(false);

        const totalLiveCCU = fetchedGames.reduce((acc, game) => acc + game.playing, 0);
        const totalVisitsz = fetchedGames.reduce((acc, game) => acc + game.visits, 0);

        setLiveCCU(totalLiveCCU.toLocaleString());
        // Format total visits to simplified format (e.g., 240M)
        settotalVisits(formatLargeNumber(totalVisitsz));
      } catch (err) {
        console.error("Error fetching games:", err);
        setError(err);
        setLoading(false);
        
        // Increment retry count to trigger immediate retry
        setRetryCount(prev => prev + 1);
      }
    };

    // Fetch data once on mount
    fetchGames();

    // Poll the data every 5 seconds
    intervalId = setInterval(fetchGames, 5000);

    // Clear the interval on unmount to avoid memory leaks
    return () => clearInterval(intervalId);
  }, [retryCount]); // Add retryCount as a dependency to trigger immediate retry

  // Update filtered games whenever games, activeFilter, or searchQuery changes
  useEffect(() => {
    console.log("Filter effect triggered with:", {
      gamesCount: games.length,
      activeFilter,
      searchQuery
    });
    
    // If search query is empty, reset to all games
    if (searchQuery === "" && activeFilter === "All") {
      console.log("Resetting to all games and clearing filters");
      clearFilters();
      return;
    }
    
    const filtered = games.filter(game => {
      const matchesFilter = activeFilter === "All" || game.genre === activeFilter;
      const matchesSearch = searchQuery === "" || 
        game.name.toLowerCase().includes(searchQuery.toLowerCase());
      
      const matches = matchesFilter && matchesSearch;
      return matches;
    });
    
    console.log("Filtered games count:", filtered.length);
    if (filtered.length <= 3) {
      console.log("Filtered games details:", filtered);
    }
    
    setFilteredGames(filtered);
  }, [games, activeFilter, searchQuery]);

  // Log when filter or search changes
  const handleFilterChange = (filter) => {
    console.log("Setting filter to:", filter);
    setActiveFilter(filter);
  };

  const handleSearchChange = (e) => {
    console.log("Setting search to:", e.target.value);
    setSearchQuery(e.target.value);
  };

  const clearSearch = () => {
    console.log("Clearing search");
    setSearchQuery("");
  };

  // Retry button handler
  const handleRetry = () => {
    setLoading(true);
    setRetryCount(prev => prev + 1);
  };

  // Get unique genres for filter - ensure no duplicates
  const uniqueGenres = games.length > 0 
    ? ["All", ...Array.from(new Set(games.map(game => game.genre)))]
    : ["All"];

  console.log("Unique genres:", uniqueGenres);

  // Clear filters
  const clearFilters = () => {
    console.log("Clearing all filters");
    setActiveFilter("All");
    setSearchQuery("");
    // Force reset to original games
    setFilteredGames(games);
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-[300px]">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-purple-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex flex-col items-center justify-center min-h-[300px] p-6 bg-black/50 rounded-lg border border-red-500/20">
        <p className="text-red-400 mb-4">Error loading games: {error.message}</p>
        <button 
          onClick={handleRetry}
          className="px-4 py-2 bg-purple-600 hover:bg-purple-700 text-white rounded-md transition-colors"
        >
          Retry Now
        </button>
        <p className="text-gray-400 text-sm mt-2">Auto-retrying in a few seconds...</p>
      </div>
    );
  }

  console.log("Rendering with:", {
    totalGames: games.length,
    filteredGames: filteredGames.length,
    activeFilter,
    searchQuery
  });

  return (
    <section className="bg-black py-24 md:py-32 relative overflow-hidden" id="games">
      {/* Subtle grid background */}
      <div className="absolute inset-0 opacity-5">
        <div className="w-full h-full" style={{
          backgroundImage: `
            linear-gradient(to right, rgba(255, 255, 255, 0.05) 1px, transparent 1px),
            linear-gradient(to bottom, rgba(255, 255, 255, 0.05) 1px, transparent 1px)
          `,
          backgroundSize: '40px 40px'
        }}></div>
      </div>
      
      {/* Minimal accent */}
      <div className="absolute top-1/3 left-1/4 w-64 h-64 bg-purple-500 rounded-full filter blur-[150px] opacity-5"></div>
      <div className="absolute bottom-1/3 right-1/4 w-64 h-64 bg-pink-500 rounded-full filter blur-[150px] opacity-5"></div>
      
      <div className="container mx-auto px-4 relative z-10">
        <div className="max-w-6xl mx-auto">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            viewport={{ once: true }}
            className="mb-16 md:mb-20"
          >
            <div className="h-px w-16 bg-gradient-to-r from-purple-500 to-pink-500 mb-8"></div>
            <h2 className="text-3xl md:text-5xl font-bold text-white mb-6">
              Our <span className="gradient-text">Games</span>
            </h2>
            <p className="text-gray-400 max-w-2xl">
              At Sparkle, our mission is to explore every genre to captivate and
              entertain our players. We intend to conquer every genre.
            </p>
          </motion.div>

          {/* Filters and Search */}
          <div className="mb-12 flex flex-col md:flex-row justify-between items-start md:items-center space-y-4 md:space-y-0">
            {/* Genre Filters - Ensure unique buttons */}
            <div className="flex flex-wrap gap-2">
              {uniqueGenres.map((genre, index) => (
                <button
                  key={index}
                  onClick={() => handleFilterChange(genre)}
                  className={`px-4 py-2 rounded-full text-sm font-medium transition-all duration-300 ${
                    activeFilter === genre
                      ? "bg-purple-600 text-white"
                      : "bg-white/5 text-gray-300 hover:bg-white/10"
                  }`}
                >
                  {genre}
                </button>
              ))}
            </div>
            
            {/* Search */}
            <div className="relative w-full md:w-64">
              <input
                type="text"
                placeholder="Search games..."
                value={searchQuery}
                onChange={handleSearchChange}
                className="w-full bg-white/5 border border-white/10 rounded-lg py-2 px-4 text-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-purple-500/50 focus:border-transparent"
              />
              {searchQuery && (
                <button
                  onClick={clearSearch}
                  className="absolute right-10 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-white"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              )}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
            </div>
          </div>

          {/* Active filters indicator */}
          {(activeFilter !== "All" || searchQuery) && (
            <div className="mb-6 flex items-center">
              <span className="text-sm text-gray-400 mr-2">Active filters:</span>
              {activeFilter !== "All" && (
                <span className="bg-purple-600/20 text-purple-300 text-xs px-3 py-1 rounded-full mr-2">
                  {activeFilter}
                </span>
              )}
              {searchQuery && (
                <span className="bg-blue-600/20 text-blue-300 text-xs px-3 py-1 rounded-full mr-2">
                  "{searchQuery}"
                </span>
              )}
              <button 
                onClick={clearFilters}
                className="text-xs text-gray-400 hover:text-white ml-2 flex items-center"
              >
                <span>Clear all</span>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3 ml-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
          )}

          {/* Games Grid */}
          {filteredGames.length > 0 ? (
            <GameGrid games={filteredGames} />
          ) : (
            <div className="flex flex-col items-center justify-center py-16 text-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-16 w-16 text-gray-600 mb-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1}
                  d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <h3 className="text-xl text-white font-medium mb-2">No games found</h3>
              <p className="text-gray-400">
                Try adjusting your search or filter to find what you're looking for.
              </p>
              <button
                onClick={clearFilters}
                className="mt-4 px-4 py-2 bg-white/10 hover:bg-white/15 text-white rounded-lg transition-colors"
              >
                Reset Filters
              </button>
            </div>
          )}

          {/* Coming Soon Section */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            viewport={{ once: true }}
            className="mt-20 pt-12 border-t border-white/5 text-center"
          >
            <h3 className="text-2xl font-bold text-white mb-4">
              More Games <span className="gradient-text">Coming Soon</span>
            </h3>
            <p className="text-gray-400 max-w-xl mx-auto mb-8">
              Stay updated with our latest releases and announcements by following us on our social media channels.
            </p>

            <div className="flex flex-col sm:flex-row items-center justify-center gap-4">
              <a
                href="https://twitter.com/playSparkle"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center px-6 py-3 text-white rounded-lg bg-transparent border border-white/10 hover:border-white/30 transition-all duration-300 group"
              >
                <svg className="w-5 h-5 mr-3 text-blue-400" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z" />
                </svg>
                Follow on Twitter
                <span className="ml-2 opacity-0 group-hover:opacity-100 transition-opacity">→</span>
              </a>

              <a
                href="https://discord.gg/z4qsYPGWHr"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center px-6 py-3 text-white rounded-lg bg-transparent border border-white/10 hover:border-white/30 transition-all duration-300 group"
              >
                <svg className="w-5 h-5 mr-3 text-purple-400" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M20.317 4.3698a19.7913 19.7913 0 00-4.8851-1.5152.0741.0741 0 00-.0785.0371c-.211.3753-.4447.8648-.6083 1.2495-1.8447-.2762-3.68-.2762-5.4868 0-.1636-.3933-.4058-.8742-.6177-1.2495a.077.077 0 00-.0785-.037 19.7363 19.7363 0 00-4.8852 1.515.0699.0699 0 00-.0321.0277C.5334 9.0458-.319 13.5799.0992 18.0578a.0824.0824 0 00.0312.0561c2.0528 1.5076 4.0413 2.4228 5.9929 3.0294a.0777.0777 0 00.0842-.0276c.4616-.6304.8731-1.2952 1.226-1.9942a.076.076 0 00-.0416-.1057c-.6528-.2476-1.2743-.5495-1.8722-.8923a.077.077 0 01-.0076-.1277c.1258-.0943.2517-.1923.3718-.2914a.0743.0743 0 01.0776-.0105c3.9278 1.7933 8.18 1.7933 12.0614 0a.0739.0739 0 01.0785.0095c.1202.099.246.1981.3728.2924a.077.077 0 01-.0066.1276 12.2986 12.2986 0 01-1.873.8914.0766.0766 0 00-.0407.1067c.3604.698.7719 1.3628 1.225 1.9932a.076.076 0 00.0842.0286c1.961-.6067 3.9495-1.5219 6.0023-3.0294a.077.077 0 00.0313-.0552c.5004-5.177-.8382-9.6739-3.5485-13.6604a.061.061 0 00-.0312-.0286zM8.02 15.3312c-1.1825 0-2.1569-1.0857-2.1569-2.419 0-1.3332.9555-2.4189 2.157-2.4189 1.2108 0 2.1757 1.0952 2.1568 2.419 0 1.3332-.9555 2.4189-2.1569 2.4189zm7.9748 0c-1.1825 0-2.1569-1.0857-2.1569-2.419 0-1.3332.9554-2.4189 2.1569-2.4189 1.2108 0 2.1757 1.0952 2.1568 2.419 0 1.3332-.946 2.4189-2.1568 2.4189Z" />
                </svg>
                Join our Discord
                <span className="ml-2 opacity-0 group-hover:opacity-100 transition-opacity">→</span>
              </a>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Games;
