import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import logo from "../logo2.png";
import { FaTwitter, FaDiscord, FaInstagram } from "react-icons/fa";

const Navbar = () => {
  const [scrolled, setScrolled] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  // Navigation items
  const navItems = [
    { 
      label: <img src={logo} alt="Sparkle Logo" className="h-8 invert" />, 
      href: "#app", 
      className: "hidden md:block" 
    },
    { href: "#team", label: "Team" },
    { href: "#metrics", label: "Metrics" },
    { href: "#games", label: "Games" },
    { href: "#services", label: "Services" },
    {
      href: "#contact",
      label: "Contact",
      className: "ml-2 md:ml-4 bg-hero-gradient hover:saturate-150 text-white py-2 px-3 md:py-2 md:px-4 rounded-lg transition-all text-center hidden md:block"
    }
  ];

  // Social media items
  const socialItems = [
    {
      href: "https://discord.gg/z4qsYPGWHr",
      label: <FaDiscord className="text-xl" />,
      tooltip: "Join us on Discord"
    },
    {
      href: "https://twitter.com/playsparkle",
      label: <FaTwitter className="text-xl" />,
      tooltip: "Follow us on Twitter"
    },
    {
      href: "https://instagram.com/strawhatvert",
      label: <FaInstagram className="text-xl" />,
      tooltip: "Follow us on Instagram"
    }
  ];

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 10;
      if (isScrolled !== scrolled) {
        setScrolled(isScrolled);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrolled]);

  return (
    <motion.nav 
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className={`text-white p-5 flex justify-center items-center max-w-7xl mx-auto sticky top-0 z-[1000] transition-all duration-300`}
    >
      <div className={`flex items-center justify-between w-full space-x-3 md:space-x-5 
        ${scrolled ? 'backdrop-blur-md bg-black/70' : 'bg-black/80'} 
        border border-white/10 py-3 px-4 rounded-xl text-base transition-all duration-300`}
      >
        {/* Logo for mobile */}
        <a href="#app" className="md:hidden">
          <img src={logo} alt="Sparkle Logo" className="h-8 invert" />
        </a>

        {/* Main navigation items - desktop */}
        <div className="hidden md:flex items-center space-x-5">
          {navItems.map((item, index) => (
            <a
              key={index}
              href={item.href}
              className={`hover:bg-white/10 p-2 px-3 rounded-lg transition-all duration-200 text-gray-200 hover:text-white ${
                item.className || ""
              }`}
            >
              {item.label}
            </a>
          ))}
        </div>

        {/* Social Media Links - desktop */}
        <div className="hidden md:flex items-center space-x-4">
          {socialItems.map((item, index) => (
            <a
              key={index}
              href={item.href}
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-400 hover:text-white transition-colors duration-200"
              title={item.tooltip}
            >
              {item.label}
            </a>
          ))}
        </div>

        {/* Mobile menu button */}
        <button 
          className="md:hidden p-2 focus:outline-none"
          onClick={() => setMobileMenuOpen(true)}
        >
          <span className="block w-6 h-0.5 bg-white mb-1"></span>
          <span className="block w-6 h-0.5 bg-white mb-1"></span>
          <span className="block w-6 h-0.5 bg-white"></span>
        </button>
      </div>

      {/* Mobile menu */}
      <div className={`fixed inset-0 bg-black/95 z-[999] flex flex-col items-center justify-center transition-all duration-300 ${mobileMenuOpen ? 'opacity-100 visible' : 'opacity-0 invisible'}`}>
        <div className="flex flex-col items-center space-y-6">
          {navItems.filter(item => !item.className?.includes('hidden')).map((item, index) => (
            <a
              key={index}
              href={item.href}
              className="text-xl font-medium hover:text-pink-400 transition-all duration-200"
              onClick={() => setMobileMenuOpen(false)}
            >
              {typeof item.label === 'string' ? item.label : 'Home'}
            </a>
          ))}
          <a
            href="#contact"
            className="bg-hero-gradient hover:saturate-150 text-white font-medium py-3 px-6 rounded-lg transition-all duration-200 mt-4"
            onClick={() => setMobileMenuOpen(false)}
          >
            Contact us
          </a>
        </div>
        
        <div className="flex space-x-6 mt-12">
          {socialItems.map((item, index) => (
            <a
              key={index}
              href={item.href}
              target="_blank"
              rel="noopener noreferrer"
              className="text-2xl hover:text-pink-400 transition-all duration-200"
              title={item.tooltip}
            >
              {item.label}
            </a>
          ))}
        </div>
        
        <button 
          className="absolute top-6 right-6 p-2"
          onClick={() => setMobileMenuOpen(false)}
        >
          <span className="block w-8 h-0.5 bg-white rotate-45"></span>
          <span className="block w-8 h-0.5 bg-white -rotate-45 -translate-y-0.5"></span>
        </button>
      </div>
    </motion.nav>
  );
};

export default Navbar;
