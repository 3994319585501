import React from "react";
import { motion } from "framer-motion";

const GameGrid = ({ games }) => {
  // Sort games based on the 'playing' property in descending order
  const sortedGames = [...games].sort((a, b) => b.playing - a.playing);

  function formatNumber(num) {
    if (num >= 1_000_000_000) {
      return (num / 1_000_000_000).toFixed(0) + 'B';
    } else if (num >= 1_000_000) {
      return (num / 1_000_000).toFixed(0) + 'M';
    } else if (num >= 1_000) {
      return (num / 1_000).toFixed(0) + 'K';
    } else {
      return num.toString();
    }
  }

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 }
  };

  return (
    <motion.div 
      variants={container}
      initial="hidden"
      whileInView="show"
      viewport={{ once: true, amount: 0.1 }}
    >
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8">
        {sortedGames.map((game, index) => (
          <motion.div
            key={index}
            variants={item}
            className="group relative bg-black rounded-xl overflow-hidden border border-white/5 hover:border-white/20 transition-all duration-300"
            whileHover={{ 
              y: -5,
              transition: { duration: 0.2 }
            }}
          >
            {/* Image container */}
            <div className="relative aspect-video overflow-hidden">
              {/* Gradient overlay on hover */}
              <div className="absolute inset-0 bg-gradient-to-t from-black via-black/50 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300 z-10"></div>
              
              {/* Game image */}
              <img
                src={game.thumbnailUrl}
                alt={game.name}
                className="w-full h-full object-cover transition-transform duration-500 group-hover:scale-105"
                loading="lazy"
              />
              
              {/* Favorites badge */}
              <div className="absolute top-3 left-3 z-20">
                <div className="bg-black/40 backdrop-blur-sm text-white text-xs px-3 py-1 rounded-full flex items-center space-x-1 border border-white/10">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3 text-yellow-400" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                  </svg>
                  <span>{formatNumber(game.favoritedCount)}</span>
                </div>
              </div>
              
              {/* Genre badge */}
              <div className="absolute top-3 right-3 z-20">
                <div className="bg-black/40 backdrop-blur-sm text-white text-xs px-3 py-1 rounded-full border border-white/10">
                  {game.genre}
                </div>
              </div>
              
              {/* Play button */}
              <div className="absolute inset-0 flex items-center justify-center z-20 opacity-0 group-hover:opacity-100 transition-opacity duration-300 transform translate-y-2 group-hover:translate-y-0">
                <a
                  href={`https://www.roblox.com/games/${game.rootPlaceId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="bg-white/10 backdrop-blur-md border border-white/20 hover:bg-white/20 text-white font-medium py-2 px-6 rounded-lg transition-all duration-200 flex items-center space-x-2"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                  <span>Play Now</span>
                </a>
              </div>
            </div>

            {/* Game details */}
            <div className="p-5 text-white">
              <h2 className="font-bold text-xl truncate mb-3">{game.name}</h2>
              
              <div className="flex justify-between items-center">
                <div className="flex flex-col space-y-1">
                  <div className="flex items-center">
                    <span className="inline-block w-1.5 h-1.5 rounded-full bg-red-500 mr-2 animate-pulse"></span>
                    <span className="text-sm">
                      <span className="font-medium text-white">{formatNumber(game.playing)}</span>
                      <span className="text-gray-400 ml-1">playing</span>
                    </span>
                  </div>
                  <div className="text-xs text-gray-500 flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3 mr-1 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                    </svg>
                    {formatNumber(game.visits)} visits
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        ))}
      </div>
    </motion.div>
  );
};

export default GameGrid;
