import React from "react";
import { motion } from "framer-motion";

const getMetrics = (liveCCU, totalVisits) => [
  {
    amount: "21K",
    label: "Peak Concurrent Users",
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M13 10V3L4 14h7v7l9-11h-7z" />
      </svg>
    ),
    color: "text-purple-400",
    isStatic: true
  },
  {
    amount: "72M",
    label: "Play Sessions",
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
      </svg>  
    ),
    color: "text-blue-400",
    isStatic: true
  },
  {
    amount: totalVisits,
    label: "Total Visits",
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
      </svg>
    ),
    color: "text-green-400",
    isStatic: false
  },
  {
    amount: liveCCU,
    label: "Total Live Players",
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
      </svg>
    ),
    color: "text-pink-400",
    isStatic: false
  },
];

function useAnimatedNumber(targetValue, duration = 1500) {
  const [displayValue, setDisplayValue] = React.useState(targetValue);

  React.useEffect(() => {
    let start = parseInt(displayValue.replace(/,/g, ""), 10) || 0;
    let end = parseInt(targetValue.replace(/,/g, ""), 10) || 0;
    let startTime = null;

    const step = (timestamp) => {
      if (!startTime) startTime = timestamp;
      const progress = Math.min((timestamp - startTime) / duration, 1);
      const current = Math.floor(start + (end - start) * progress);

      setDisplayValue(current.toLocaleString());

      if (progress < 1) {
        requestAnimationFrame(step);
      }
    };

    requestAnimationFrame(step);
  }, [targetValue, duration]);

  return displayValue;
}

// Helper function to extract number and suffix
function extractNumberAndSuffix(value) {
  if (typeof value !== 'string') return { number: value, suffix: '' };
  
  const lastChar = value.slice(-1);
  if (lastChar === 'K' || lastChar === 'M' || lastChar === 'B') {
    return {
      number: value.slice(0, -1),
      suffix: lastChar
    };
  }
  
  return { number: value, suffix: '' };
}

function MetricCard({ amount, label, icon, index, color, isStatic }) {
  // Always extract number and suffix, regardless of whether it's static
  const { number, suffix } = extractNumberAndSuffix(amount);
  
  // Always call useAnimatedNumber (no conditional hooks)
  const animatedNumber = useAnimatedNumber(number, 1500);
  
  // Determine the final display value
  const displayValue = isStatic ? amount : animatedNumber + suffix;

  return (
    <motion.div 
      initial={{ opacity: 0, y: 10 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay: index * 0.1 }}
      viewport={{ once: true }}
      className="group"
    >
      <div className="flex items-center space-x-3 mb-2">
        <div className={`${color} opacity-80 group-hover:opacity-100 transition-opacity duration-300`}>
          {icon}
        </div>
        <span className="text-gray-400 text-sm font-medium">{label}</span>
      </div>
      <h3 className="text-4xl md:text-5xl font-bold text-white tracking-tight group-hover:text-transparent group-hover:bg-clip-text group-hover:bg-gradient-to-r group-hover:from-purple-400 group-hover:to-pink-400 transition-all duration-300 truncate">
        {displayValue}
      </h3>
    </motion.div>
  );
}

const MetricsSection = ({ liveCCU, totalVisits }) => {
  const metrics = getMetrics(liveCCU, totalVisits);

  // Simple countdown state & effect
  const [timeLeft, setTimeLeft] = React.useState(3);

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeft((prev) => {
        // when it hits 1, jump back to 3
        return prev === 1 ? 3 : prev - 1;
      });
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <section className="bg-black py-24 md:py-32 relative overflow-hidden" id="metrics">
      {/* Subtle grid background */}
      <div className="absolute inset-0 opacity-5">
        <div className="w-full h-full" style={{
          backgroundImage: `
            linear-gradient(to right, rgba(255, 255, 255, 0.05) 1px, transparent 1px),
            linear-gradient(to bottom, rgba(255, 255, 255, 0.05) 1px, transparent 1px)
          `,
          backgroundSize: '40px 40px'
        }}></div>
      </div>
      
      {/* Minimal accent */}
      <div className="absolute top-1/3 left-1/4 w-64 h-64 bg-purple-500 rounded-full filter blur-[150px] opacity-5"></div>
      <div className="absolute bottom-1/3 right-1/4 w-64 h-64 bg-pink-500 rounded-full filter blur-[150px] opacity-5"></div>
      
      <div className="container mx-auto px-4 relative z-10">
        <div className="max-w-6xl mx-auto">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            viewport={{ once: true }}
            className="mb-16 md:mb-24"
          >
            <div className="h-px w-16 bg-gradient-to-r from-purple-500 to-pink-500 mb-8"></div>
            <h2 className="text-3xl md:text-5xl font-bold text-white mb-6">
              Impressive <span className="gradient-text">Metrics</span>
            </h2>
            <p className="text-gray-400 max-w-2xl">
              We've achieved incredible results with our games. In just three months of operation, 
              we reached these impressive milestones:
            </p>
          </motion.div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-12 gap-y-12">
            {metrics.map((metric, index) => (
              <MetricCard
                key={index}
                amount={metric.amount}
                label={metric.label}
                icon={metric.icon}
                index={index}
                color={metric.color}
                isStatic={metric.isStatic}
              />
            ))}
          </div>

          {/* Live update indicator */}
          <motion.div 
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ delay: 0.8 }}
            viewport={{ once: true }}
            className="flex items-center mt-12 space-x-2 opacity-60"
          >
            <span className="inline-block w-1.5 h-1.5 bg-green-500 rounded-full animate-pulse"></span>
            <p className="text-xs text-gray-500">
              Live data • Refreshing in {timeLeft}s
            </p>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default MetricsSection;
