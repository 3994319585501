import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";

const RippleButton = ({ children, className, href }) => {
  const handleRipple = (event) => {
    const button = event.currentTarget;
    const circle = document.createElement("span");
    const diameter = Math.max(button.clientWidth, button.clientHeight);
    const radius = diameter / 2;

    circle.style.width = circle.style.height = `${diameter}px`;
    circle.style.left = `${event.clientX - button.getBoundingClientRect().left - radius
      }px`;
    circle.style.top = `${event.clientY - button.getBoundingClientRect().top - radius
      }px`;
    circle.classList.add("ripple");

    const ripple = button.getElementsByClassName("ripple")[0];
    if (ripple) ripple.remove();

    button.appendChild(circle);

    setTimeout(() => {
      circle.remove();
    }, 600);
  };

  const Tag = href ? "a" : "button";

  return (
    <Tag
      className={`relative overflow-hidden ${className}`}
      onClick={handleRipple}
      href={href}
      target="_blank"
    >
      {children}
      <style jsx>{`
        .ripple {
          position: absolute;
          border-radius: 50%;
          transform: scale(0);
          animation: ripple 600ms linear;
          background-color: rgba(255, 255, 255, 0.7);
        }
        @keyframes ripple {
          to {
            transform: scale(4);
            opacity: 0;
          }
        }
      `}</style>
    </Tag>
  );
};

const handleScrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({ behavior: "smooth" });
  }
};

const teamMembers = [
  { name: "strawhatvert", role: "cap n cook", userid: 3810754327, isFounder: true },
  { name: "teabled", role: "heisenberg", userid: 2366538947, isCoFounder: true },
  { name: "tafzri", role: "game producer", userid: 1057895133 },
  { name: "avwzz", role: "game producer", userid: 941184424 },
  { name: "reachify", role: "game producer", userid: 87145254 },
  { name: "headr0ws", role: "game producer", userid: 55499399 },
  { name: "9hoste", role: "game producer", userid: 1104258874 },
  { name: "Vortbloxian", role: "game producer", userid: 142362222 },
];

const TeamMemberCard = ({ name, role, image, userid, isFounder, isCoFounder }) => {
  return (
    <motion.div 
      className={`group transition-all duration-300 ${isFounder ? "glitch-owner" : ""}`}
      whileHover={{ y: -5, transition: { duration: 0.2 } }}
    >
      <a
        href={`https://www.roblox.com/users/${userid}`}
        target="_blank"
        rel="noopener noreferrer"
        className="block"
      >
        <div className="relative">
          <div className="w-28 h-28 md:w-36 md:h-36 lg:w-40 lg:h-40 rounded-xl overflow-hidden bg-gradient-to-br from-purple-900/30 to-pink-600/30 p-1 transition-all duration-300 group-hover:from-purple-600/40 group-hover:to-pink-600/40">
            <div className="w-full h-full rounded-lg overflow-hidden relative">
              {/* Glow effect on hover */}
              <div className="absolute inset-0 bg-hero-gradient opacity-0 group-hover:opacity-20 transition-opacity duration-300"></div>
              
              {/* Image */}
              <img
                src={image}
                alt={name}
                className="object-cover w-full h-full group-hover:scale-110 transition-transform duration-300"
                loading="lazy"
              />
              
              {/* Subtle overlay */}
              <div className="absolute inset-0 bg-gradient-to-t from-black/60 via-transparent to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
            </div>
          </div>
          
          {/* Status indicator for special roles */}
          {isFounder && (
            <div className="absolute -top-2 -right-2 bg-hero-gradient text-white text-xs px-2 py-1 rounded-full shadow-lg">
              Founder
            </div>
          )}
          {isCoFounder && (
            <div className="absolute -top-2 -right-2 bg-gradient-to-r from-blue-500 to-purple-500 text-white text-xs px-2 py-1 rounded-full shadow-lg">
              Co-Founder
            </div>
          )}
        </div>
        
        <div className="mt-4 text-center">
          <h3
            className={`text-white text-lg font-semibold ${isFounder ? "glitch-text relative inline-block" : ""} transition-all duration-300 group-hover:text-transparent group-hover:bg-clip-text group-hover:bg-gradient-to-r group-hover:from-purple-400 group-hover:to-pink-400`}
            data-text={name}
          >
            {name}
          </h3>
          <p className="text-gray-400 text-sm mt-1 capitalize transition-all duration-300 group-hover:text-gray-300">{role}</p>
        </div>
      </a>
      
      <style jsx>{`
        /* Glitch effect only applies if parent has glitch-owner class and we hover over it */
        .glitch-owner .glitch-text {
          position: relative;
        }
        .glitch-owner:hover .glitch-text::before,
        .glitch-owner:hover .glitch-text::after {
          content: attr(data-text);
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          overflow: hidden;
          color: #0ff; /* Cyan shade */
        }

        /* Top layer (offset and clipped) */
        .glitch-owner:hover .glitch-text::before {
          animation: glitchTop 1s infinite steps(2, start);
          clip: rect(0, 9999px, 0, 0);
        }

        /* Bottom layer (offset and clipped) */
        .glitch-owner:hover .glitch-text::after {
          animation: glitchBot 1s infinite steps(2, start);
          clip: rect(0, 9999px, 0, 0);
          color: #f0f; /* Magenta shade */
        }

        @keyframes glitchTop {
          0% {
            clip: rect(0, 9999px, 0, 0);
            transform: translate(0, 0);
          }
          20% {
            clip: rect(5px, 9999px, 30px, 0);
            transform: translate(-5px, -5px);
          }
          40% {
            clip: rect(10px, 9999px, 5px, 0);
            transform: translate(5px, 5px);
          }
          60% {
            clip: rect(2px, 9999px, 25px, 0);
            transform: translate(-5px, 2px);
          }
          80% {
            clip: rect(15px, 9999px, 5px, 0);
            transform: translate(5px, -2px);
          }
          100% {
            clip: rect(0, 9999px, 0, 0);
            transform: translate(0, 0);
          }
        }

        @keyframes glitchBot {
          0% {
            clip: rect(0, 9999px, 0, 0);
            transform: translate(0, 0);
          }
          20% {
            clip: rect(10px, 9999px, 20px, 0);
            transform: translate(5px, -5px);
          }
          40% {
            clip: rect(5px, 9999px, 30px, 0);
            transform: translate(-5px, 5px);
          }
          60% {
            clip: rect(10px, 9999px, 10px, 0);
            transform: translate(2px, -5px);
          }
          80% {
            clip: rect(0, 9999px, 15px, 0);
            transform: translate(-2px, 5px);
          }
          100% {
            clip: rect(0, 9999px, 0, 0);
            transform: translate(0, 0);
          }
        }
      `}</style>
    </motion.div>
  );
};

const Who = () => {
  const [team, setTeam] = useState(teamMembers);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchImages = async () => {
      setIsLoading(true);
      try {
        // Create a batch request URL with all user IDs
        const userIdsParam = teamMembers.map(member => `userIds=${member.userid}`).join('&');
        const batchUrl = `https://thumbnails.roblox.com/v1/users/avatar-headshot?size=420x420&format=Png&isCircular=false&${userIdsParam}`;
        
        // Use a CORS proxy if needed
        const proxyUrl = `https://api.allorigins.win/get?url=${encodeURIComponent(batchUrl)}`;
        
        const response = await fetch(proxyUrl);
        const data = await response.json();
        const parsedData = JSON.parse(data.contents);
        
        // Create a map of userId to imageUrl for easy lookup
        const imageMap = {};
        parsedData.data.forEach(item => {
          if (item.state === "Completed") {
            imageMap[item.targetId] = item.imageUrl;
          }
        });
        
        // Update team members with their images
        const updatedTeam = teamMembers.map(member => ({
          ...member,
          image: imageMap[member.userid] || null
        }));
        
        setTeam(updatedTeam);
      } catch (error) {
        console.error("Error fetching team images:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchImages();
  }, []);

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
  };

  return (
    <section className="bg-black py-32 relative overflow-hidden" id="team">
      {/* Background elements */}
      <div className="absolute inset-0 overflow-hidden pointer-events-none">
        {/* Subtle grid pattern */}
        <div className="absolute inset-0 opacity-5">
          <div className="w-full h-full" style={{
            backgroundImage: `
              linear-gradient(to right, rgba(255, 255, 255, 0.05) 1px, transparent 1px),
              linear-gradient(to bottom, rgba(255, 255, 255, 0.05) 1px, transparent 1px)
            `,
            backgroundSize: '40px 40px'
          }}></div>
        </div>
        
        <div className="absolute top-1/4 -left-20 w-80 h-80 bg-purple-500 rounded-full filter blur-[100px] opacity-20 animate-pulse-slow"></div>
        <div className="absolute bottom-1/4 -right-20 w-80 h-80 bg-pink-500 rounded-full filter blur-[100px] opacity-20 animate-pulse-slow" style={{animationDelay: '2s'}}></div>
        <div className="absolute w-full h-px bg-gradient-to-r from-transparent via-white/20 to-transparent top-0"></div>
        <div className="absolute w-full h-px bg-gradient-to-r from-transparent via-white/20 to-transparent bottom-0"></div>
      </div>
      
      <div className="container mx-auto px-4 text-center relative z-10">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
        >
          <div className="h-px w-16 bg-gradient-to-r from-purple-500 to-pink-500 mb-8 mx-auto"></div>
          <span className="inline-block px-4 py-1 bg-white/10 backdrop-blur-sm rounded-full text-sm font-medium text-white/80 border border-white/10 mb-6">
            Our People
          </span>
          <h2 className="text-4xl md:text-6xl font-black text-white mb-4">
            Meet the <span className="gradient-text">Team</span>
          </h2>
          <p className="text-lg font-light text-gray-300 w-full md:w-7/12 mx-auto mb-16">
            We are an independently owned game studio founded and operated by two
            passionate creators. Each member of our team brings a unique
            perspective, resulting in a diverse and dynamic experience.
          </p>
        </motion.div>

        {isLoading ? (
          <div className="flex items-center justify-center min-h-[300px]">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-purple-500"></div>
          </div>
        ) : (
          <motion.div
            variants={containerVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-8 md:gap-10 justify-items-center"
          >
            {team.map((member, i) => (
              <motion.div key={i} variants={itemVariants}>
                <TeamMemberCard
                  name={member.name}
                  role={member.role}
                  image={member.image}
                  userid={member.userid}
                  isFounder={member.isFounder}
                  isCoFounder={member.isCoFounder}
                />
              </motion.div>
            ))}
          </motion.div>
        )}
      </div>

      <div className="container mx-auto px-4 text-center max-w-4xl mt-24">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          viewport={{ once: true }}
          className="bg-white/5 backdrop-blur-sm border border-white/10 rounded-xl p-8 md:p-10 hover:bg-white/8 transition-all duration-300"
        >
          <svg className="w-12 h-12 text-purple-400 mx-auto mb-6" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <path d="M0 216C0 149.7 53.7 96 120 96h8c17.7 0 32 14.3 32 32s-14.3 32-32 32h-8c-30.9 0-56 25.1-56 56v8h64c35.3 0 64 28.7 64 64v64c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V320 288 216zm256 0c0-66.3 53.7-120 120-120h8c17.7 0 32 14.3 32 32s-14.3 32-32 32h-8c-30.9 0-56 25.1-56 56v8h64c35.3 0 64 28.7 64 64v64c0 35.3-28.7 64-64 64H320c-35.3 0-64-28.7-64-64V320 288 216z"/>
          </svg>
          
          <blockquote className="text-xl md:text-2xl font-medium text-white italic leading-relaxed">
            "Here at Sparkle, we focus on quality. We're dedicated to creating the best games on the ROBLOX platform. We're passionate about delivering experiences that are both immersive and engaging."
          </blockquote>
          
          <div className="mt-6">
            <p className="text-purple-300 font-semibold">strawhatvert</p>
            <p className="text-gray-400 text-sm">Founder & CEO</p>
          </div>
        </motion.div>
        
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.3 }}
          viewport={{ once: true }}
          className="mt-16"
        >
          <RippleButton
            className="bg-white/10 hover:bg-white/15 text-white font-medium py-3 px-6 rounded-lg transition-all duration-200 border border-white/10 hover:border-white/20"
            href="#contact" 
            onClick={(e) => {
              e.preventDefault();
              document.getElementById('contact').scrollIntoView({ behavior: 'smooth' });
            }}
          >
            Join Our Team
          </RippleButton>
        </motion.div>
      </div>
    </section>
  );
};

export default Who;