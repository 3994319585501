import React, { useState, useEffect, useRef } from "react";
import { motion, useInView, AnimatePresence } from "framer-motion";

// Minimalist service item with line connector
const ServiceItem = ({ number, title, description, isLast = false }) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, margin: "-100px" });
  
  return (
    <div ref={ref} className="relative">
      {/* Vertical line connector */}
      {!isLast && (
        <div className="absolute left-6 top-12 w-px bg-gradient-to-b from-purple-500 to-transparent h-full opacity-30"></div>
      )}
      
      <motion.div 
        initial={{ opacity: 0, x: -20 }}
        animate={isInView ? { opacity: 1, x: 0 } : { opacity: 0, x: -20 }}
        transition={{ duration: 0.5, delay: number * 0.1 }}
        className="flex items-start mb-16 group"
      >
        {/* Number indicator */}
        <div className="flex-shrink-0 w-12 h-12 rounded-full bg-white/5 border border-white/10 flex items-center justify-center mr-6 relative z-10 group-hover:border-purple-500/50 transition-all duration-300">
          <span className="text-purple-400 font-mono text-lg">{number}</span>
        </div>
        
        <div>
          <h3 className="text-xl md:text-2xl font-bold text-white mb-2 group-hover:text-purple-300 transition-colors duration-300">{title}</h3>
          <p className="text-gray-400 max-w-xl">{description}</p>
        </div>
      </motion.div>
    </div>
  );
};

// Minimalist genre pill
const GenrePill = ({ genre, delay = 0 }) => {
  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.9 }}
      whileInView={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.4, delay }}
      viewport={{ once: true }}
      whileHover={{ y: -3, transition: { duration: 0.2 } }}
      className="px-4 py-2 rounded-full border border-white/10 bg-white/5 backdrop-blur-sm text-white text-sm font-medium hover:border-purple-500/30 hover:bg-purple-500/5 transition-all duration-300"
    >
      {genre}
    </motion.div>
  );
};

// Client type with hover effect
const ClientType = ({ icon, title, description, color, delay = 0 }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay }}
      viewport={{ once: true }}
      className="group cursor-pointer"
    >
      <div className={`h-px w-0 group-hover:w-full mb-6 transition-all duration-500 ${color}`}></div>
      <div className="flex items-start">
        <div className={`w-12 h-12 rounded-full ${color} bg-opacity-20 flex items-center justify-center mr-4 group-hover:scale-110 transition-transform duration-300`}>
          {icon}
        </div>
        <div>
          <h4 className="text-white text-lg font-medium mb-2 group-hover:text-purple-300 transition-colors duration-300">{title}</h4>
          <p className="text-gray-400 text-sm">{description}</p>
        </div>
      </div>
    </motion.div>
  );
};

// Creative header with animated code/design elements
const CreativeHeader = () => {
  const containerRef = useRef(null);
  
  // Animation variants for floating elements
  const floatingVariants = {
    initial: { opacity: 0, scale: 0.8 },
    animate: { 
      opacity: 1, 
      scale: 1,
      transition: { duration: 0.8 }
    }
  };
  
  return (
    <div ref={containerRef} className="relative py-24 md:py-32 overflow-hidden">
      {/* Abstract design elements */}
      <div className="absolute inset-0 overflow-hidden">
        {/* Code-like elements */}
        <motion.div 
          initial={{ opacity: 0, x: -100 }}
          animate={{ opacity: 0.15, x: 0 }}
          transition={{ duration: 1 }}
          className="absolute left-4 top-1/4 text-xs md:text-sm font-mono text-purple-300 hidden md:block"
        >
          {Array(15).fill(0).map((_, i) => (
            <div key={i} className="my-2 whitespace-nowrap">
              <span className="text-pink-400">function</span> <span className="text-blue-400">createGame</span>
              <span className="text-white">(</span>
              <span className="text-green-400">idea</span>
              <span className="text-white">) {'{'}
                <span className="opacity-70">// Your vision, our expertise</span>
              {'}'}</span>
            </div>
          ))}
        </motion.div>
        
        <motion.div 
          initial={{ opacity: 0, x: 100 }}
          animate={{ opacity: 0.15, x: 0 }}
          transition={{ duration: 1, delay: 0.3 }}
          className="absolute right-4 bottom-1/4 text-xs md:text-sm font-mono text-purple-300 hidden md:block"
        >
          {Array(10).fill(0).map((_, i) => (
            <div key={i} className="my-2 whitespace-nowrap text-right">
              <span className="text-pink-400">class</span> <span className="text-blue-400">GameStudio</span>
              <span className="text-white"> {'{'}</span>
              <span className="text-green-400"> render</span>
              <span className="text-white">() {'{'} </span>
              <span className="text-yellow-400">return</span>
              <span className="text-white"> highQualityGames; {'}'} {'}'}</span>
            </div>
          ))}
        </motion.div>
      </div>
      
      {/* Floating geometric shapes */}
      <div className="absolute inset-0 pointer-events-none">
        <motion.div 
          variants={floatingVariants}
          initial="initial"
          animate="animate"
          className="absolute top-1/4 left-1/4 w-16 h-16 md:w-24 md:h-24 rounded-lg border border-purple-500/30 bg-purple-500/5 transform rotate-12"
          style={{ animationDelay: '0.2s' }}
        />
        <motion.div 
          variants={floatingVariants}
          initial="initial"
          animate="animate"
          transition={{ delay: 0.2 }}
          className="absolute top-1/3 right-1/4 w-12 h-12 md:w-20 md:h-20 rounded-full border border-pink-500/30 bg-pink-500/5"
        />
        <motion.div 
          variants={floatingVariants}
          initial="initial"
          animate="animate"
          transition={{ delay: 0.4 }}
          className="absolute bottom-1/4 left-1/3 w-20 h-20 md:w-32 md:h-32 rounded-lg border border-blue-500/30 bg-blue-500/5 transform -rotate-12"
        />
      </div>
      
      {/* Main content */}
      <div className="container mx-auto px-4 relative z-10">
        <div className="max-w-4xl mx-auto text-center">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="mb-6"
          >
            <span className="inline-block px-4 py-1 bg-white/5 backdrop-blur-sm rounded-full text-sm font-medium text-white/80 border border-white/10">
              Game Development Services
            </span>
          </motion.div>
          
          <motion.h1
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            className="text-5xl md:text-7xl font-black text-white mb-6 leading-tight"
          >
            We Create <span className="gradient-text">Exceptional</span> Gaming Experiences
          </motion.h1>
          
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
            className="text-lg md:text-xl text-gray-300 mb-10"
          >
            From concept to launch, our team specializes in developing immersive games 
            across all genres with precision and creativity.
          </motion.p>
          
          {/* <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5, delay: 0.6 }}
            className="flex flex-wrap justify-center gap-4"
          >
            <a 
              href="#contact" 
              className="bg-gradient-to-r from-purple-600 to-pink-600 hover:saturate-150 text-white font-medium py-3 px-8 rounded-lg transition-all duration-300 shadow-lg shadow-purple-500/20 hover:shadow-purple-500/40"
            >
              Start Your Project
            </a>
            <a 
              href="#games" 
              className="bg-transparent border border-white/20 hover:border-white/40 text-white font-medium py-3 px-8 rounded-lg transition-all duration-300"
            >
              View Our Work
            </a>
          </motion.div> */}
        </div>
      </div>
      
      {/* Animated dots grid */}
      <div className="absolute inset-0 overflow-hidden pointer-events-none">
        <div className="absolute inset-0 opacity-20" style={{
          backgroundImage: 'radial-gradient(circle, rgba(255,255,255,0.1) 1px, transparent 1px)',
          backgroundSize: '30px 30px'
        }}></div>
      </div>
    </div>
  );
};

// Simple grid pattern background
const GridPattern = () => {
  return (
    <div className="absolute inset-0 opacity-5">
      <div className="w-full h-full" style={{
        backgroundImage: `
          linear-gradient(to right, rgba(255, 255, 255, 0.05) 1px, transparent 1px),
          linear-gradient(to bottom, rgba(255, 255, 255, 0.05) 1px, transparent 1px)
        `,
        backgroundSize: '40px 40px'
      }}></div>
    </div>
  );
};

// Enhanced genre item with hover effects
const GenreItem = ({ genre, icon, delay = 0 }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.4, delay }}
      viewport={{ once: true }}
      whileHover={{ 
        scale: 1.05, 
        backgroundColor: "rgba(139, 92, 246, 0.1)",
        borderColor: "rgba(139, 92, 246, 0.3)",
        transition: { duration: 0.2 } 
      }}
      className="flex flex-col items-center justify-center p-4 rounded-lg border border-white/5 bg-white/3 backdrop-blur-sm transition-all duration-300 h-full"
    >
      <div className="text-purple-400 mb-3 opacity-80">
        {icon}
      </div>
      <span className="text-white font-medium text-sm">{genre}</span>
    </motion.div>
  );
};

const Services = () => {
  return (
    <section id="services" className="bg-black relative overflow-hidden">
      {/* Subtle background grid */}
      <GridPattern />
      
      {/* Minimal glowing accent */}
      <div className="absolute top-1/3 left-1/4 w-64 h-64 bg-purple-500 rounded-full filter blur-[150px] opacity-10 animate-float"></div>
      <div className="absolute bottom-1/3 right-1/4 w-64 h-64 bg-pink-500 rounded-full filter blur-[150px] opacity-10 animate-float-delayed"></div>
      
      {/* Creative Header instead of Parallax Gallery */}
      <CreativeHeader />
      
      {/* Main content */}
      <div className="container mx-auto px-4 py-24 relative z-10">
        {/* Genres section - redesigned grid layout */}
        <div className="mb-32">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            viewport={{ once: true }}
            className="mb-12"
          >
            <h2 className="text-3xl md:text-4xl font-bold text-white">
              Expertise Across All <span className="gradient-text">Genres</span>
            </h2>
            <div className="h-px w-16 bg-gradient-to-r from-purple-500 to-pink-500 mt-4"></div>
          </motion.div>
          
          {/* Redesigned genre grid with icons */}
          <div className="grid grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-3 md:gap-4">
            <GenreItem 
              genre="Action" 
              delay={0.1} 
              icon={
                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M13 10V3L4 14h7v7l9-11h-7z" />
                </svg>
              } 
            />
            <GenreItem 
              genre="Adventure" 
              delay={0.15} 
              icon={
                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              } 
            />
            <GenreItem 
              genre="RPG" 
              delay={0.2} 
              icon={
                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              } 
            />
            <GenreItem 
              genre="Strategy" 
              delay={0.25} 
              icon={
                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M9 17V7m0 10a2 2 0 01-2 2H5a2 2 0 01-2-2V7a2 2 0 012-2h2a2 2 0 012 2m0 10a2 2 0 002 2h2a2 2 0 002-2M9 7a2 2 0 012-2h2a2 2 0 012 2m0 10V7m0 10a2 2 0 002 2h2a2 2 0 002-2V7a2 2 0 00-2-2h-2a2 2 0 00-2 2" />
                </svg>
              } 
            />
            <GenreItem 
              genre="Simulation" 
              delay={0.3} 
              icon={
                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M19.428 15.428a2 2 0 00-1.022-.547l-2.387-.477a6 6 0 00-3.86.517l-.318.158a6 6 0 01-3.86.517L6.05 15.21a2 2 0 00-1.806.547M8 4h8l-1 1v5.172a2 2 0 00.586 1.414l5 5c1.26 1.26.367 3.414-1.415 3.414H4.828c-1.782 0-2.674-2.154-1.414-3.414l5-5A2 2 0 009 10.172V5L8 4z" />
                </svg>
              } 
            />
            <GenreItem 
              genre="Sports" 
              delay={0.35} 
              icon={
                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" />
                </svg>
              } 
            />
            <GenreItem 
              genre="Puzzle" 
              delay={0.4} 
              icon={
                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M11 4a2 2 0 114 0v1a1 1 0 001 1h3a1 1 0 011 1v3a1 1 0 01-1 1h-1a2 2 0 100 4h1a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1v-1a2 2 0 10-4 0v1a1 1 0 01-1 1H7a1 1 0 01-1-1v-3a1 1 0 00-1-1H4a2 2 0 110-4h1a1 1 0 001-1V7a1 1 0 011-1h3a1 1 0 001-1V4z" />
                </svg>
              } 
            />
            <GenreItem 
              genre="Racing" 
              delay={0.45} 
              icon={
                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8" />
                </svg>
              } 
            />
            <GenreItem 
              genre="Horror" 
              delay={0.5} 
              icon={
                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              } 
            />
            <GenreItem 
              genre="Educational" 
              delay={0.55} 
              icon={
                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" />
                </svg>
              } 
            />
            <GenreItem 
              genre="Casual" 
              delay={0.6} 
              icon={
                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              } 
            />
            <GenreItem 
              genre="Metaverse" 
              delay={0.65} 
              icon={
                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              } 
            />
          </div>
        </div>
        
        {/* Development Process - vertical timeline */}
        <div className="mb-32">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            viewport={{ once: true }}
            className="mb-12"
          >
            <h2 className="text-3xl md:text-4xl font-bold text-white">
              Our <span className="gradient-text">Development</span> Process
            </h2>
            <div className="h-px w-16 bg-gradient-to-r from-purple-500 to-pink-500 mt-4"></div>
          </motion.div>
          
          <div className="pl-4">
            <ServiceItem 
              number="01"
              title="Expert Development & Marketing"
              description="Our experienced team takes over development, marketing, and ongoing optimization, ensuring your game thrives in the long run."
            />
            
            <ServiceItem 
              number="02"
              title="Revenue Sharing"
              description="You will benefit from continued revenue generated by your game through a transparent revenue-sharing model."
            />
            
            <ServiceItem 
              number="03"
              title="Hassle-Free Transition"
              description="Sit back and relax! We handle everything from the sale to future management, allowing you to focus on your next big idea."
              isLast={true}
            />
          </div>
        </div>
        
        {/* Client Types - minimalist grid */}
        <div className="mb-32">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            viewport={{ once: true }}
            className="mb-12"
          >
            <h2 className="text-3xl md:text-4xl font-bold text-white">
              Who We <span className="gradient-text">Work With</span>
            </h2>
            <div className="h-px w-16 bg-gradient-to-r from-purple-500 to-pink-500 mt-4"></div>
          </motion.div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12">
            <ClientType 
              icon={<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-blue-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" />
              </svg>}
              title="Web3 Enthusiasts"
              description="Blockchain integration, NFTs, and decentralized gaming experiences"
              color="bg-blue-500"
              delay={0.1}
            />
            
            <ClientType 
              icon={<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-purple-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" />
              </svg>}
              title="Corporations"
              description="Enterprise solutions, branded experiences, and training simulations"
              color="bg-purple-500"
              delay={0.2}
            />
            
            <ClientType 
              icon={<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-pink-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
              </svg>}
              title="Artists"
              description="Interactive art experiences, visual novels, and creative collaborations"
              color="bg-pink-500"
              delay={0.3}
            />
            
            <ClientType 
              icon={<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-green-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
              </svg>}
              title="Individuals"
              description="Personal projects, indie games, and passion projects brought to life"
              color="bg-green-500"
              delay={0.4}
            />
          </div>
        </div>
        
        {/* Minimalist CTA */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
          className="text-center"
        >
          <h3 className="text-2xl md:text-3xl font-bold text-white mb-6">
            Ready to bring your <span className="gradient-text">Game Idea</span> to life?
          </h3>
          
          <div className="inline-block relative group">
            <a 
              href="#contact" 
              className="inline-block bg-transparent border border-white/20 hover:border-purple-500/50 text-white font-medium py-3 px-8 rounded-full transition-all duration-300 group-hover:pl-12"
            >
              Get in Touch
            </a>
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              className="h-5 w-5 absolute left-4 top-1/2 -translate-y-1/2 opacity-0 group-hover:opacity-100 transition-all duration-300 text-purple-400" 
              fill="none" 
              viewBox="0 0 24 24" 
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
            </svg>
          </div>
        </motion.div>
      </div>
      
      {/* Subtle divider */}
      <div className="h-px w-full bg-gradient-to-r from-transparent via-white/10 to-transparent"></div>
    </section>
  );
};

export default Services; 